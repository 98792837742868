/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-09 10:05:34
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-09 18:41:10
 */
import GetData from "@/utils/request.js"
const { $get, $post } = new GetData()

export const getHomeIndex = ({ title = "", page = 1 }) => {
  return $get(`/index.php/admin/home/homeindex?title=${title}`, { page })
}

export const createHomeIndex = ({
  title,
  img,
  img_m,
  link,
  note = "",
  sort = 1,
}) =>
  $post("/index.php/admin/home/home_add", {
    title,
    img,
    img_m,
    link,
    note,
    sort,
  })

export const getOneHomeIndex = (id) =>
  $get(`/index.php/admin/home/home_edit?id=${id}`)

export const editHomeIndex = ({
  id,
  title,
  img,
  img_m,
  link,
  note = "",
  sort = 1,
}) =>
  $post(`/index.php/admin/home/home_edit`, {
    id,
    title,
    img,
    img_m,
    link,
    note,
    sort,
  })

export const deleteHomeIndex = (id) =>
  $post(`/index.php/admin/home/home_delete`, { id })
/**
 * @method switchHomeIndex 改变首页横幅广告状态
 * @param {number} id 数据id
 * @param {number} show  1隐藏 0显示
 */
export const switchHomeIndex = (id, show) =>
  $get(`/index.php/admin/home/home_switchs?id=${id}&show=${show}`)

/**
 * 分类广告
 */
export const getGoodsRule = (page = 1) => {
  return $get(`/index.php/admin/product_rule/goodsrule`, { page })
}

export const getGRIndex = ({ id, title = "", page = 1 }) => {
  return $get(`/index.php/admin/home/grindex`, { id, title, page })
}

export const createGRIndex = ({
  id,
  title,
  img,
  img_m,
  link,
  note = "",
  sort = 1,
}) =>
  $post("/index.php/admin/home/gr_add", {
    id,
    title,
    img,
    img_m,
    link,
    note,
    sort,
  })

export const getOneGRIndex = (id) =>
  $get(`/index.php/admin/home/gr_edit?id=${id}`)

export const editGRIndex = ({
  id,
  title,
  img,
  img_m,
  link,
  note = "",
  sort = 1,
}) =>
  $post(`/index.php/admin/home/gr_edit`, {
    id,
    title,
    img,
    img_m,
    link,
    note,
    sort,
  })

export const deleteGRIndex = (id) =>
  $post(`/index.php/admin/home/gr_delete`, { id })
/**
 * @method switchGRIndex 改变首页横幅广告状态
 * @param {number} id 数据id
 * @param {number} show  1隐藏 0显示
 */
export const switchGRIndex = (id, show) =>
  $get(`/index.php/admin/home/gr_switchs?id=${id}&show=${show}`)

/**热门品牌 */
export const getHotBrandCats = (page = 1) =>
  $get(`/index.php/admin/product_rule/goodsrule`, {page})

export const getHotBrandList = ({ id, title = "", page = 1 }) =>
  $get(`/index.php/admin/hot_brand/index`, { id, title, page })

export const createHotBrand = ({ id, title, title_en, img, sort, note = "" }) =>
  $post("/index.php/admin/hot_brand/add", {
    id,
    title,
    title_en,
    img,
    sort,
    note,
  })
export const getOneHotBrand = (id) =>
  $get(`/index.php/admin/hot_brand/edit?id=${id}`)

export const editHotBrand = ({ id, title, title_en, img, sort, note = "" }) =>
  $post(`/index.php/admin/hot_brand/edit`, {
    id,
    title,
    title_en,
    img,
    sort,
    note,
  })

export const deleteHotBrand = (id) =>
  $post(`/index.php/admin/hot_brand/delete`, { id })
/**
 * @method switchHotBrand 改变首页横幅广告状态
 * @param {number} id 数据id
 * @param {number} show  1隐藏 0显示
 */
export const switchHotBrand = (id, show) =>
  $get(`/index.php/admin/hot_brand/switchs?id=${id}&show=${show}`)

/**Google advets */
export const getGoogleAds = (page) =>
  $get(`/index.php/admin/home/googleindex?page=${page}`)

export const getOneGoogleAds = (id) =>
  $get(`/index.php/admin/google/edit?id=${id}`)

export const editGoogleAds = ({ id, title, img, content }) =>
  $post(`/index.php/admin/google/edit`, {
    id,
    title,
    img,
    content,
  })

export default {
  getHomeIndex,
  createHomeIndex,
  getOneHomeIndex,
  editHomeIndex,
  deleteHomeIndex,
  switchHomeIndex,
  getGoodsRule,
  getGRIndex,
  createGRIndex,
  getOneGRIndex,
  editGRIndex,
  deleteGRIndex,
  switchGRIndex,
  getHotBrandCats,
  getHotBrandList,
  createHotBrand,
  getOneHotBrand,
  editHotBrand,
  deleteHotBrand,
  switchHotBrand,
  getGoogleAds,
  getOneGoogleAds,
  editGoogleAds,
}
