/*
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2020-08-04 11:19:14
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-09 11:55:28
 */
/**
 * 用户信息
 */
const get = function () {
  let res = localStorage.getItem('USER_INFO');
  if (!res) return {};
  try {
    let p = JSON.parse(res);
    if (Object.prototype.toString.call(p) == '[object Object]') {
      return p;
    } else {
      return {}
    }
  } catch (e) {
    return {}
  }
};
const set = function (data) {
  localStorage.setItem('USER_INFO', JSON.stringify(data))
};

const remove = function () {
  localStorage.removeItem('USER_INFO');
}

const getAuth = function () {
  let info = get();
  if (info.token) {
    return info;
  } else {
    return '';
  }
}

export default {
  get,
  set,
  remove,
  getAuth
}