<template>
  <form :id="formId" :class="apear == 'button' ? 'btn' : ''">
    <div class="c-file-wrap">
      <input
        @change="changeFiles($event)"
        :disabled="disabled"
        :accept="accept"
        type="file"
        name="file"
        id="files"
        :multiple="multiple"
      />
      <div class="upload-cover">
        <i class="el-icon-plus"></i>
      </div>
      <div v-if="val && !disabled" class="c-mask">重新上传</div>
      <div v-if="val" class="c-thumb">
        <img :src="val ? constants.imgUrl + val : ''" />
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: 'upload',
  props: {
    /**默认值 */
    val: {
      type: String,
      default: '',
    },
    /* 支持的文件类型 诸如：image/png, image/gif, image/jpeg audio/mp4, video/mp4 */
    accept: {
      type: String,
      default: 'image/png,image/gif,image/jpeg',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    apear: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],
      formId: 'form-' + +new Date(),
      auth: {},
    }
  },
  methods: {
    /**
     * @method changeFiles 选择文件
     */
    changeFiles(e) {
      // 过滤符合的
      let files = e.target.files || e.dataTransfer.files,
        leng = files.length,
        arr = [],
        _this = this
      if (!files.length) {
        return
      }
      arr = this.fileFilter(files)
      // 有符合条件的文件
      if (arr.length > 0) {
        this.$emit('confirm', { files: arr })
        document.getElementById(this.formId).reset()
      }
    },
    /**
     * @method: fileFilter 过滤文件
     */
    fileFilter(files) {
      let arr = []
      for (var i = 0, file; (file = files[i]); i++) {
        let isV = this.accept == '*' ? true : this.returnVia(files[i])
        isV && arr.push(files[i])
      }
      return arr
    },
    returnVia(file) {
      let acceptArr = this.accept.split(','),
        fileArr = file.type.split('/'),
        arr = []
      acceptArr.forEach(function(it) {
        let newArr = it.split('/'),
          obj = { t: newArr[0], f: newArr[1] }
        arr.push(obj)
      })

      let a = arr.some(function(it, index, array) {
        if (it.f == '*') {
          return it.t == fileArr[0]
        } else {
          return it.t == fileArr[0] && it.f == fileArr[1]
        }
      })
      return a
    },
  },
}
</script>
<style scoped lang="scss">
.btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
}
.c-file-wrap {
  width: 98px;
  height: 98px;
  overflow: hidden;
  position: relative;
  border: 1px dashed #dcdfe6;
  border-radius: 4px;
  z-index: 1;
}
.c-file-wrap input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
  height: 100px;
}
.upload-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  z-index: 1;
  i {
    font-size: 30px;
    color: #dcdfe6;
  }
}
.c-thumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
  }
}
.c-file-wrap:hover .c-mask {
  display: block;
}
.c-mask {
  display: none;
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 100px;
}
</style>
