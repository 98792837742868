/*
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2020-08-04 11:19:14
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-03 09:57:57
 */
export default {
    path:"/login",
    name:"login",
    component:()=>import("@/pages/login"),
    meta:{
         // 凡是为true，是不需要验证的页面
         // authRequired: true,
        requiresAuth: true
    }
}
