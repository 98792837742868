<template >
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="800px"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="dialog-body" v-loading="loading">
      <div class="flex-bt">
        <div>
          <!-- 还需要根据type做一些判断 -->
          <upload ref="upload" @confirm="confirm" :accept="accepts[type]"></upload>
        </div>
        <div>
          分类：
          <el-cascader
            v-if="showDialog"
            v-model="categoryIds"
            :options="materialCategory[type]"
            :props="{ expandTrigger: 'click', value: 'id'  }"
          ></el-cascader>
        </div>
      </div>
      <div>
        <el-table :data="files" style="width: 100%">
          <el-table-column prop="name" label="素材名称" width="180"></el-table-column>
          <el-table-column prop="type" label="素材类型" width="180"></el-table-column>
          <el-table-column prop="size" label="大小">
            <template slot-scope="scope">{{scope.row.size|size}}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="delFile(scope.$index)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="confirmUpload">{{btnText}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import upload from "./upload";
import Vue from "vue";
export default {
  name: "aliupload",
  components: {
    upload
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: [Number, String],
      default: 0 // 媒体类型[0:图片,1:音频,2:视频]
    }
  },
  data() {
    return {
      showDialog: false,
      categoryIds: [""],
      files: [],
      uploader: null,
      uploaderNum: {
        success: 0,
        failure: 0
      },
      loading: false,
      title: "",
      accepts: [
        "image/png,image/gif,image/jpeg",
        "audio/mp3,audio/mpeg",
        "video/mpeg4,video/mpeg,video/mp4"
      ],
      order: 0,
      btnText: '开始上传'
    };
  },
  computed: {
    ...mapGetters({
      materialCategory: "material/materialCategoryGetter"
    })
  },
  watch: {
    show(val) {
      this.showDialog = val;
      // 打开弹窗的时候请求数据
      if (val) {
        this.title = "上传" + Vue.filter("materialType")(this.type);
      }
    }
  },
  methods: {
    ...mapActions({
      getMediaTree: "material/materialCategoryAction",
      materialAction: "material/materialAction",
      mediaData: "material/materialGetter"
    }),
    delFile(index) {
      this.files.splice(index, 1);
    },
    close() {
      let cat = this.categoryIds[this.categoryIds.length - 1];
      this.categoryIds = [];
      this.files = [];
      this.$refs["upload"].$el.reset();
      this.$emit("close", cat);
    },
    confirm({ files }) {
      this.files =[...this.files, ...files];
    },
    /**
     * 开始上传
     */
    confirmUpload() {
      let cid = this.categoryIds[this.categoryIds.length - 1];
      let msg = "";
      if (this.files.length == 0) {
        msg = "请至少选择一个文件";
      }
      if (cid == "" || !cid) {
        msg = "请选择分类";
      }
      if (this.files.length == 0 || cid == "" || !cid) {
        this.$message({
          message: msg,
          type: "warning"
        });
        return;
      }
      this.loading = true;
      this.btnText = '上传中';
      this.initAliUpload();
    },
    /**
     * @method initAliUpload 初始化阿里云上传
     */
    initAliUpload() {
      let _this = this;
      console.log(_this.constants);
      _this.uploader = new AliyunUpload.Vod({
        //阿里账号ID，必须有值 ，值的来源https://help.aliyun.com/knowledge_detail/37196.html
        userId: _this.constants.aliyunUserId,
        //分片大小默认1M，不能小于100K
        partSize: 1048576,
        //并行上传分片个数，默认5
        parallel: 5,
        //网络原因失败时，重新上传次数，默认为3
        retryCount: 3,
        //网络原因失败时，重新上传间隔时间，默认为2秒
        retryDuration: 2,
        // 开始上传
        onUploadstarted: function(uploadInfo) {
          // 设置上传凭证
          _this.getUploadAuth(uploadInfo);
        },
        // 文件上传成功
        onUploadSucceed: function(uploadInfo) {
          ++_this.uploaderNum.success;
          ++_this.order;
        },
        // 文件上传失败
        onUploadFailed: function(uploadInfo, code, message) {
          ++_this.uploaderNum.failure;
          ++_this.order;
        },
        // 文件上传进度，单位：字节
        onUploadProgress: function(uploadInfo, totalSize, loadedPercent) {},
        // 上传凭证超时
        onUploadTokenExpired: function(uploadInfo) {
          console.log("expired");
          // 刷新auth
          _this.refreshUploadToken();
          // auth过期刷新
          resumeUploadWithAuth(_this.auth.uploadAuth);
        },
        //全部文件上传结束
        onUploadEnd: function(uploadInfo) {
          _this.$message({
            message:
              "上传成功" +
              _this.uploaderNum.success +
              "个,失败" +
              _this.uploaderNum.failure +
              "个",
            type: "success"
          });
          // 重置数据
          _this.uploader.cleanList();
          _this.files = [];
          _this.uploaderNum = {
            success: 0,
            failure: 0
          };
          _this.loading = false;
          _this.btnText = '开始上传';
          _this.order = 0;
        }
      });
      this.files.forEach(file => {
        _this.uploader.addFile(file, null, null, null, null);
      });
      _this.uploader.startUpload();
      _this.$refs["upload"].$el.reset();
    },
    /**
     * @method: getUploadAuth 获取上传凭证
     * @param: {Object} uploadInfo 阿里云提供的上传信息
     */
    async getUploadAuth(uploadInfo) {
      let res = await this.api.material.materialCreate({
          categoryId: this.categoryIds[this.categoryIds.length - 1],
          mimeType: this.getExtension(this.files[this.order].name),
          name: this.files[this.order].name,
          type: this.type
        }),
        _this = this;
      if (res && res.code == 200) {
        _this.auth = res.data;
        _this.uploader.setUploadAuthAndAddress(
          uploadInfo,
          _this.auth.uploadAuth,
          _this.auth.uploadAddress,
          _this.auth.mediaId
        );
      }
    },
    async refreshUploadToken() {
      let res = await this.api.material.materialVidRefresh(this.auth.mediaId);
      if(res && res.code == 200) {
        // 刷新成功
        _this.auth = res.data;
      }else {
        this.$message({
          type: 'warning',
          message: '刷新上传token失败'
        });
      }
    },
    /**
     * @method: getExtension 获取扩展名
     * @param: {String} fileName 文件名 
     */
    getExtension(fileName) {
      let i = fileName.lastIndexOf(".");
      return fileName.substring(i + 1).toLowerCase();
    }
  }
};
</script>
<style scoped>
</style>