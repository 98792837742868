/*
 * @Description: 用户模块接口
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-11 18:17:37
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2023-02-04 17:00:25
 */
import GetData from '@/utils/request.js'
const { $get, $post } = new GetData()

//登陆
// title=&start_time=&end_time=
export const getUsers = ({
  page = 1,
  title = '',
  start_time = '',
  end_time = '',
}) => {
  return $get('/index.php/admin/user/index', {
    page,
    title,
    start_time,
    end_time,
  })
}

export const switchUser = (id, stat) =>
  $get(`/index.php/admin/user/banned?id=${id}&state=${stat}`)

export const setNote = (id, note = '') =>
  $post(`/index.php/admin/user/edit`, { id, note })

export const exportData = () => $post(`/index.php/admin/user/downloadexcel`)

export const getUserLabels = (id, page = 1) =>
  $get(`/index.php/admin/user/user_label?id=${id}`, { page })

export const getShops = ({ title = '', page = 1 }) =>
  $get(`/index.php/admin/shop/index`, { title, page })

export const checkShop = (data) => $post(`/index.php/admin/shop/edit`, data)

export const getNotes = ({ title = '', page = 1 }) =>
  $get(`/index.php/admin/system_notice/index`, { title, page })

export const getNoteUsers = ({ title, page = 1 } = { page: 1 }) =>
  $get(`/index.php/admin/system_notice/userlist`, { title, page })

export const publishNote = (data) =>
  $post(`/index.php/admin/system_notice/add`, data)

export const previewNote = (id) =>
  $get(`/index.php/admin/system_notice/view?id=${id}`)

export default {
  getUsers,
  switchUser,
  setNote,
  exportData,
  getUserLabels,
  getShops,
  checkShop,
  getNotes,
  getNoteUsers,
  publishNote,
  previewNote,
}
