/*
 * @Description: 举报中心接口
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-14 13:39:35
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-14 13:46:33
 */
import GetData from "@/utils/request.js"
const { $get, $post } = new GetData()

export const getGoodsReports = ({ gtitle = "", utitle = "", page = 1 }) => {
  return $get("/index.php/admin/report/goodsindex", { gtitle, utitle, page })
}

export const getOneGoodsReport = (id) => {
  return $get(`/index.php/admin/report/goodsedit?id=${id}`)
}

export const createGoodsReport = (data) =>
  $post(`/index.php/admin/report/goodsedit`, data)

export const getUserReports = ({ title = "", page = 1 }) => {
  return $get("/index.php/admin/report/userindex", { title, page })
}

export const getOneUserReport = (id) => {
  return $get(`/index.php/admin/report/useredit?id=${id}`)
}

/**
 *
 * @param {object} data
 * @param {number} data.status 审核结果，1.不予处理，2.封禁商品
 * @param {number} data.opinion 审核意见
 * @returns
 */
export const createUserReport = (data) =>
  $post(`/index.php/admin/report/useredit`, data)

export default {
  getGoodsReports,
  getOneGoodsReport,
  createGoodsReport,
  getUserReports,
  getOneUserReport,
  createUserReport,
}
