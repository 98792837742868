export default [
    {
        path:"/ads",
        name:"ads",
        component: () =>import("@/pages/ads/index"),
        meta:{
            breadcrumb:[
                {
                    path:"/ads",
                    text:"廣告管理"
                },
                {
                    path:"",
                    text:"首頁橫幅"
                }
            ]
        }
    },
    
    {
        path:"/ads/goodsCats",
        name:"adsGoodsCats",
        component: () =>import("@/pages/ads/goodsCat"),
        meta:{
            breadcrumb:[
                {
                    path:"/ads",
                    text:"廣告管理"
                },
                {
                    path:"",
                    text:"商品分類橫幅"
                }
            ]
        }
    },
    {
        path:"/ads/hotBrand",
        name:"adsHotBrand",
        component: () =>import("@/pages/ads/hotBrand"),
        meta:{
            breadcrumb:[
                {
                    path:"/ads",
                    text:"廣告管理"
                },
                {
                    path:"",
                    text:"熱門品牌配置"
                }
            ]
        }
    },
    {
        path:"/ads/googleAds",
        name:"adsGoogleAds",
        component: () =>import("@/pages/ads/googleAds"),
        meta:{
            breadcrumb:[
                {
                    path:"/ads",
                    text:"廣告管理"
                },
                {
                    path:"",
                    text:"Google廣告配置"
                }
            ]
        }
    },
    
    
]