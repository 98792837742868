<template>
  <form :id="formId">
    <div class="c-file-wrap">
      <input
        @change="changeFiles($event)"
        :disabled="disable"
        :accept="accept"
        type="file"
        name="file"
        id="files"
        :multiple="multiple"
      />
      <el-button class="btn-cover">选择文件</el-button>
    </div>
  </form>
</template>
<script>
export default {
  name: "upload",
  props: {
    /* 支持的文件类型 诸如：image/png, image/gif, image/jpeg audio/mp4, video/mp4 */
    accept: {
      type: String,
      default: "image/png,image/gif,image/jpeg",
    },
    disable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      files: [],
      formId: "form-" + +new Date(),
      auth: {},
    };
  },
  methods: {
    /**
     * @method changeFiles 选择文件
     */
    changeFiles(e) {
      // 过滤符合的
      let files = e.target.files || e.dataTransfer.files,
        leng = files.length,
        arr = [],
        _this = this;
      if (!files.length) {
        return;
      }
      arr = this.fileFilter(files);
      // 有符合条件的文件
      if (arr.length > 0) {
        this.$emit("confirm", { files: arr });
        document.getElementById(this.formId).reset();
      }
    },
    /**
     * @method: fileFilter 过滤文件
     */
    fileFilter(files) {
      let arr = [];
      for (var i = 0, file; (file = files[i]); i++) {
        let isV = this.accept == "*" ? true : this.returnVia(files[i]);
        isV && arr.push(files[i]);
      }
      return arr;
    },
    returnVia(file) {
      let acceptArr = this.accept.split(","),
        fileArr = file.type.split("/"),
        arr = [];
      acceptArr.forEach(function (it) {
        let newArr = it.split("/"),
          obj = { t: newArr[0], f: newArr[1] };
        arr.push(obj);
      });

      let a = arr.some(function (it, index, array) {
        if (it.f == "*") {
          return it.t == fileArr[0];
        } else {
          console.log(fileArr[0], fileArr[1]);
          return it.t == fileArr[0] && it.f == fileArr[1];
        }
      });
      return a;
    },
  },
};
</script>
<style scoped>
.c-file-wrap{
  width: 98px;
  height: 40px;
  overflow: hidden;
  position: relative;
}
.c-file-wrap input{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  height: 100px;
}
</style>
