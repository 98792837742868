/*
 * @Description: 新闻管理路由
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 21:52:59
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-10 15:02:57
 */
export default [
  {
    path: "/news",
    name: "news",
    component: () => import("@/pages/news/index"),
    meta: {
      breadcrumb: [
        {
          path: "",
          text: "新聞管理",
        },
      ],
    },
  },
  {
    path: "/news/:type",
    name: "newsCreate",

    component: () => import("@/pages/news/createNews"),
    meta: {
      breadcrumb: [
        {
          path: "/news",
          text: "新聞管理",
        },
        {
          path: "",
          text: "新聞",
        },
      ],
      menu: "/news",
    },
  },
]
