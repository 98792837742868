/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2021-04-27 00:50:40
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-08 21:56:18
 */
export default [
  {
    path: '/system/account',
    name: 'systemAccount',
    component: () => import('@/pages/system/account'),
    meta: {
      breadcrumb: [
        {
          path: '/system/account',
          text: '系統管理'
        },
        {
          path: '',
          text: '管理員列表'
        }
      ]
    }
  }
]
