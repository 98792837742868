import GetData from '@/utils/request.js'
const { $get, $post } = new GetData()

export const getCats = (
  { title = '', page = 1, level = 1 } = { title: '', page: 1, level: 1 }
) => {
  return $get('/index.php/admin/product_rule/index', { title, page, level })
}

export const switchCat = (id, show) =>
  $get(`index.php/admin/product_rule/switchs?id=${id}&show=${show}`)

export const getCatByLevel = (level) =>
  $get(`/index.php/admin/product_rule/add?level=${level}`)

export const createCat = (data) =>
  $post(`/index.php/admin/product_rule/add`, data)

export const getOneCat = (id) =>
  $get(`/index.php/admin/product_rule/edit?id=${id}`)

export const editCat = (data) =>
  $post(`/index.php/admin/product_rule/edit`, data)

export const deleteCat = (id) =>
  $post(`/index.php/admin/product_rule/delete`, { id })

/**
 * 商品
 */

export const getGoodsDegrees = () =>
  $get(`index.php/admin/product/product_rule`)

export const getGoods = (data) => $get(`/index.php/admin/product/index`, data)

//  1.解封，2.封禁
export const switchGoods = (id, status) =>
  $get(`/index.php/admin/product/banned?id=${id}&status=${status}`)

export const addNode = (id, note = '') =>
  $post(`/index.php/admin/product/edit`, { id, note })

export const exportGoods = () => $post(`/index.php/admin/product/downloadexcel`)

export const getExposures = (page) =>
  $get(`/index.php/admin/exposure/index`, { page })

export const createExposure = (data) =>
  $post(`/index.php/admin/exposure/add`, data)

export const getOneExposure = (id) =>
  $get(`/index.php/admin/exposure/edit?id=${id}`)

export const editExposure = (data) =>
  $post(`index.php/admin/exposure/edit`, data)

export const deleteExposure = (id) =>
  $post(`/index.php/admin/exposure/delete`, { id })

export const getPaymentCats = (page) =>
  $get(`/index.php/admin/exposure_order/product_rule`, { page })

export const getPayments = ({ page = 1, pname = '', uname = '', rid = '' }) =>
  $get(`/index.php/admin/exposure_order/index`, { page, pname, uname, rid })

export const getOnePayment = (id) =>
  $get(`/index.php/admin/exposure_order/edit?id=${id}`)

export const editPayment = (data) =>
  $post(`/index.php/admin/exposure_order/edit`, data)

export const stopPayment = (id) =>
  $get(`/index.php/admin/exposure_order/stop?id=${id}`)

// 违禁词
export const getWords = ({ title, page = 1 }) =>
  $get(`/index.php/admin/pdw/index`, { title, page })

export const createWords = (name) => $post('/index.php/admin/pdw/add', { name })

export const editWords = (id, name) =>
  $post('/index.php/admin/pdw/edit', { id, name })

export const deleteWords = (id) => $post('/index.php/admin/pdw/delete', { id })

export const exportWords = () => $post('/index.php/admin/pdw/downloadexcel')

export const importWords = (file) =>
  $post('/index.php/admin/pdw/importExcel', file)

export default {
  getCats,
  switchCat,
  getCatByLevel,
  createCat,
  getOneCat,
  editCat,
  deleteCat,
  getGoodsDegrees,
  getGoods,
  switchGoods,
  addNode,
  exportGoods,
  getExposures,
  createExposure,
  getOneExposure,
  editExposure,
  deleteExposure,
  getPaymentCats,
  getPayments,
  getOnePayment,
  editPayment,
  stopPayment,
  getWords,
  createWords,
  editWords,
  deleteWords,
  exportWords,
  importWords,
}
