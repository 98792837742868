/*
 * @Description: 帮助中心管理接口
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2021-12-22 21:27:03
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-14 10:47:01
 */
import GetData from "@/utils/request.js"
const { $get, $post } = new GetData()

export const getCats = ({ level = 1, title = "", page = 1 }) =>
  $get("/index.php/admin/article_rule/index?", {
    level,
    title,
    page,
  })
export const getLevels = (level) =>
  $get(`/index.php/admin/article_rule/add?level=${level}`)

export const createCat = (data) =>
  $post(`/index.php/admin/article_rule/add`, data)

export const editCat = (data) =>
  $post(`/index.php/admin/article_rule/edit`, data)

export const getOneCat = (id) =>
  $get(`/index.php/admin/article_rule/edit?id=${id}`)

export const deleteCat = (id) =>
  $post(`/index.php/admin/article_rule/delete`, { id })

export const switchCat = (id, show) =>
  $get(`/index.php/admin/article_rule/switchs?id=${id}&show=${show}`)

export const getArticleCats = () => $get(`/index.php/admin/article/rule`)

export const getArticles = ({ title, page = 1, rid = "" }) => {
  return $get("/index.php/admin/article/index", { title, page, rid })
}

export const getOneArticle = (id) => {
  return $get("/index.php/admin/article/edit", { id })
}

/**
 * @param {object} data
 * @param {number} data.rid 分类id
 * @param {string} data.title 文章标题
 * @param {string} data.title_en 英文文章标题
 * @param {string} data.abstract 文章概要
 * @param {string} data.abstract_en 英文文章概要
 * @param {string} data.details 文章内容
 * @param {string} data.details_en 英文文章内容
 * @param {number} data.sort 权重
 * @returns
 */
export const createArticle = (data) => {
  return $post("/index.php/admin/article/add", data)
}
export const editArticle = (data) => {
  return $post("/index.php/admin/article/edit", data)
}
/**
 * @method switchArticle 切换article状态
 * @param {number} id 数据id
 * @param {number} show 0.开启，1.关闭
 * @param {number} type  1.常見問題，2.公告，3.展示狀態
 * @returns
 */
export const switchArticle = (id, show, type) =>
  $get(`/index.php/admin/article/switchs?id=${id}&show=${show}&type=${type}`)

export const deleteArticle = (id) =>
  $post("/index.php/admin/article/delete", { id })

export default {
  getCats,
  getLevels,
  getOneCat,
  createCat,
  editCat,
  deleteCat,
  switchCat,
  getArticleCats,
  getArticles,
  getOneArticle,
  createArticle,
  editArticle,
  deleteArticle,
  switchArticle,
}
