/*
 * @Description: 新闻管理接口
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2021-12-22 21:27:03
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-10 16:55:31
 */
import GetData from "@/utils/request.js"
const { $get, $post } = new GetData()

export const getNews = ({ title, page = 1 }) => {
  return $get("/index.php/admin/news/index", { title, page })
}

export const getOneNews = (id) =>{
  return $get("/index.php/admin/news/edit", { id })
}
 
/**
 * @method createNews 创建新闻
 * @param {object} data
 * @param {string} data.title
 * @param {string} data.title_en
 * @param {string} data.abstract
 * @param {string} data.abstract_en
 * @param {string} data.news_time
 * @param {string} data.details
 * @param {string} data.details_en
 * @param {string} data.sort
 * @returns
 */
export const createNews = (data) => {
  return $post("/index.php/admin/news/add", data)
}
export const editNews = (data) => {
  return $post("/index.php/admin/news/edit", data)
}

export const switchNews = (id, show) =>
  $get(`index.php/admin/news/switchs?id=${id}&show=${show}`)

export const deleteNews = (id) => $post("/index.php/admin/news/delete", { id })

export default {
  getNews,
  getOneNews,
  createNews,
  editNews,
  deleteNews,
  switchNews,
}
