/*
 * @Description: 帮助管理路由
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 21:52:59
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-12 13:15:14
 */
export default [
    {
      path: '/help',
      name: 'help',
      component: () => import('@/pages/help/index'),
      meta: {
        breadcrumb: [
          {
            path: '/help',
            text: '幫助中心管理'
          },
          {
            path: '',
            text: '文章主題'
          }
        ]
      }
    },
    {
        path: '/help/list',
        name: 'helpList',
        component: () => import('@/pages/help/list'),
        meta: {
          breadcrumb: [
            {
              path: '/help',
              text: '幫助中心管理'
            },
            {
              path: '',
              text: '文章列表'
            }
          ]
        }
      },
    {
      path: '/help/:type',
      name: 'helpCreate',
      menu: "/help/list",
      component: () => import('@/pages/help/createArticle'),
      meta: {
        breadcrumb: [
          {
            path: '/help',
            text: '幫助中心管理'
          },
          {
            path: '/help/list',
            text: '文章列表'
          },
          {
            path: '',
            text: '文章'
          }
        ]
      }
    }
  ]
  