/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-01-21 22:05:18
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-16 11:09:36
 */
module.exports = {
  // 本地开发环境
  location: "",
  // 开发环境
  development: "https://cms-api-v1.swapifly.com",
  // 测试环境
  test: "https://swapifly-api.vtl-lab.com",
  // 生产
  production: "https://cms-api-v1.swapifly.com",
}
