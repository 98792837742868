import GetData from '@/utils/request.js'
const { $get, $post } = new GetData()

export const getUserLds = () => {
  return $get('/index.php/admin/user_stats/userlds')
}

export const getVistor = ({ start_time = '', end_time = '', page = 1 }) => {
  return $get('/index.php/admin/user_stats/visitor', {
    start_time,
    end_time,
    page,
  })
}

export const exportVistor = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/user_stats/visitordownloadexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getNewUser = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/newadduser', { start_time, end_time, page })

export const exportNewUser = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/user_stats/adduserdexcel?start_time=${start_time}&end_time=${end_time}`
  )
export const getBuyer = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/buyernum', { start_time, end_time, page })

export const exportBuyer = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/user_stats/buyerdexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getSeller = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/sellernum', { start_time, end_time, page })

export const exportSeller = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/user_stats/sellerdexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getGender = ({ type = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/usersex', { type, page })

export const exportGender = ({ type = '' }) =>
  $get(`/index.php/admin/user_stats/usersexdexcel?type=${type}`)

export const getAge = ({ min, max, page = 1 }) =>
  $get('/index.php/admin/user_stats/userage', { min, max, page })

export const exportAge = ({ page = 1, min, max }) =>
  $get(
    `/index.php/admin/user_stats/useragedexcel?page=${page}&min=${min}&max=${max}`
  )

export const getArea = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/userregion', { start_time, end_time, page })

export const exportArea = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/user_stats/userregiondexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getOS = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/useros', { start_time, end_time, page })

export const exportOS = ({ start_time = '', end_time = '', page = 1 }) =>
  $get(
    `/index.php/admin/user_stats/userosdexcel?page=${page}&start_time=${start_time}&end_time=${end_time}`
  )

export const getDealNum = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/uservr', { start_time, end_time, page })

export const exportDealNum = ({ start_time = '', end_time = '', page = 1 }) =>
  $get(
    `/index.php/admin/user_stats/uservrdexcel?page=${page}&start_time=${start_time}&end_time=${end_time}`
  )

export const getDealMoney = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/user_stats/userrtv', { start_time, end_time, page })

export const exportDealMoney = ({ start_time = '', end_time = '', page = 1 }) =>
  $get(
    `/index.php/admin/user_stats/userrtvdexcel?page=${page}&start_time=${start_time}&end_time=${end_time}`
  )
export const getGoods = () => {
  return $get('/index.php/admin/product_stats/productlds')
}

export const getGoodsPublish = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/product_stats/product_rle', {
    start_time,
    end_time,
    page,
  })

export const exportGoodsPublish = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/product_stats/prledexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getGoodsSelling = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/product_stats/product_sale', {
    start_time,
    end_time,
    page,
  })

export const exportGoodsSelling = ({
  start_time = '',
  end_time = '',
  page = 1,
}) =>
  $get(
    `/index.php/admin/product_stats/psadexcel?page=${page}&start_time=${start_time}&end_time=${end_time}`
  )

export const getGoodsClicks = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/product_stats/productclick', {
    start_time,
    end_time,
    page,
  })

export const exportGoodsClicks = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/product_stats/pclkdexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getGoodsDeals = ({ start_time = '', end_time = '', page = 1 }) =>
  $get('/index.php/admin/product_stats/productcad', {
    start_time,
    end_time,
    page,
  })

export const exportGoodsDeals = ({ start_time = '', end_time = '' }) =>
  $get(
    `/index.php/admin/product_stats/pcaddexcel?start_time=${start_time}&end_time=${end_time}`
  )

export const getGoodsSearchRank = () =>
  $get('/index.php/admin/product_stats/search')

export const exportGoodsSearchRank = () =>
  $get(`/index.php/admin/product_stats/searchdexcel`)

export default {
  getUserLds,
  getVistor,
  exportVistor,
  getNewUser,
  exportNewUser,
  getBuyer,
  exportBuyer,
  getSeller,
  exportSeller,
  getGender,
  exportGender,
  getAge,
  exportAge,
  getArea,
  exportArea,
  getOS,
  exportOS,
  getDealNum,
  exportDealNum,
  getDealMoney,
  exportDealMoney,
  getGoods,
  getGoodsPublish,
  exportGoodsPublish,
  getGoodsSelling,
  exportGoodsSelling,
  getGoodsClicks,
  exportGoodsClicks,
  getGoodsDeals,
  exportGoodsDeals,
  getGoodsSearchRank,
  exportGoodsSearchRank
}
