import Axios from 'axios'
import auth from '@/utils/auth'
import Vue from 'vue'
import router from '@/router'

let urls = require('@/config')

var url = urls[process.env.NODE_ENV]
// console.log(url);
// 生成环境特殊处理
// if(process.env.NODE_ENV == 'production') {
//   url = '';
// }
const http = Axios.create({
  timeout: 30000,
  // withCredentials: true,
  baseURL: url
})
http.interceptors.request.use(
  config => {
    let userInfo = auth.getAuth()
    // console.log(userInfo, '!!!!!')
    config.headers['X-Adminid'] = userInfo.id
    config.headers['X-Token'] = userInfo.token
    if (config.method.toLowerCase() === 'get' && config.data) {
      config.params = {
        ...config.data
      }
    }
    // 过滤空格
    for (let p in config.params) {
      if (typeof config.params[p] == 'string') {
        config.params[p] = config.params[p].trim()
      }
    }
    for (let p in config.data) {
      if (typeof config.data[p] == 'string') {
        config.data[p] = config.data[p].trim()
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
http.interceptors.response.use(
  res => {
    if (res.status === 200) {
      Vue.prototype.logout = false
      if (res.data.code !== 0) {
        if(res.data.code == 2) {
          if (Vue.prototype.logout) {
            return
          }
          Vue.prototype.logout = true
          // 登录超时
          auth.remove()
          Vue.prototype.$message({
            message: res.data.message,
            type: 'warning'
          })
          router.push({
            path: '/login',
            query: {
              redirect: router.currentRoute.path
            }
          })
          return
        }
        Vue.prototype.$message({
          message: res.data.message,
          type: 'warning'
        })
      }
      return res.data
    }
  },
  error => {
    let errorInfo = {}
    if (error.response) {
      if (error.response.data) {
        let code = error.response.data.code
        errorInfo = error.response.data
        switch (code) {
          case 2: {
            if (Vue.prototype.logout) {
              break
            }
            Vue.prototype.logout = true
            // 登录超时
            auth.remove()
            errorInfo.message = error.response.data.message
            Vue.prototype.$message({
              message: errorInfo.message,
              type: 'warning'
            })
            router.push({
              path: '/login',
              query: {
                redirect: router.currentRoute.path
              }
            })
            break
          }
          case 403: {
            // 没有权限
            Vue.prototype.$message({
              message: errorInfo.message,
              type: 'warning'
            })
            break
          }
          default:
            errorInfo.message = '请求失败'
        }
      }
    }
    return Promise.reject(errorInfo)
  }
)
class RequestMethod {
  constructor() {
    this.$get = this.$get.bind(this)
    this.$post = this.$post.bind(this)
    this.$put = this.$put.bind(this)
    this.$delete = this.$delete.bind(this)
    this.$upload = this.$upload.bind(this)
    this._method = this._method.bind(this)
  }

  /**
   * 整合请求方法
   * @param { String } url 接口路由
   * @param { Object } axios配置 请求参数
   */
  _method(url, options) {
    // 处理请求
    // console.log(url, options)
    return new Promise((resolve, reject) => {
      http(url, options)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * get方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $get(url, data) {
    return this._method(url, {
      params: data,
      method: 'get'
    })
  }

  /**
   * post方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $post(url, data) {
    return this._method(url, {
      data: data,
      method: 'post',
      type: 'json'
    })
  }

  /**
   * put方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $put(url, data) {
    return this._method(url, {
      data: data,
      method: 'put',
      type: 'json'
    })
  }

  /**
   * delete方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $delete(url, data) {
    return this._method(url, {
      params: data,
      method: 'delete'
    })
  }

  /**
   * 上传方法
   * @param { String } url 接口路由
   * @param { Object } data 请求参数
   */
  $upload(url, data) {
    return this._method(url, {
      body: data,
      method: 'post',
      type: 'formData'
    })
  }
}
export default RequestMethod

// export default http;
