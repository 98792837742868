/*
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2020-08-04 11:19:14
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-14 14:13:43
 */
export default {
    path:"/home",
    name:"home",
    component:()=>import("@/pages/home"),
    meta:{
        breadcrumb:[
            {
                path:"/home",
                text:"首頁"
            }
        ]
    },
    redirect: {
        path: '/ads'
    }
}