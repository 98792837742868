/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2020-11-03 16:04:51
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-10 21:42:19
 */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "@/assets/css/public.css"
import "@/assets/css/main.css"

//暂时全局引入，后期优化
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import locale from "element-ui/lib/locale/lang/zh-TW"
Vue.use(ElementUI, { locale })

/**自定义组件 */
import my_UI from "@/components/index.js"
Vue.use(my_UI)

// api
import api from "@/api"
import { constants } from "@/utils/constants"

// 挂载api
Vue.prototype.api = api
Vue.prototype.constants = constants

Vue.config.productionTip = false

// 过滤器
import { filters } from "@/utils/filters"
filters.forEach(({ name, fun }) => {
  Vue.filter(name, function(value) {
    return fun(value)
  })
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
