/*
 * @Description: 系统接口和公用接口
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2021-12-22 21:27:03
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-10 16:02:26
 */
import GetData from "@/utils/request.js"
const { $get, $post } = new GetData()

//登陆
export const login = (data) => {
  return $post("/index.php/admin/login/index", data)
}

export const logout = () => $post("/index.php/admin/login/out")

export const uploadFile = (file) => $post("/index.php/admin/oss/Upload", file)

export const getAccounts = ({ page = 1, username }) =>
  $get(`/index.php/admin/auth_admin/index`, { page, username })

export const createAccount = (data) =>
  $post("/index.php/admin/auth_admin/save", data)

export const editAccount = (data) =>
  $post("/index.php/admin/auth_admin/edit", data)

export const deleteAccount = (id) =>
  $post("/index.php/admin/auth_admin/delete", { id })

export const switchAccount = (id, show) =>
  $get(`index.php/admin/auth_admin/banned?id=${id}&status=${show}`)

export default {
  login,
  uploadFile,
  logout,
  getAccounts,
  createAccount,
  editAccount,
  deleteAccount,
  switchAccount,
}
