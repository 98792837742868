<template>
  <div id="app">
    <div id="op-stage">{{s}}</div>
    <router-view/>
    <!-- hotfix -->
  </div>
</template>
<script>
let urls = require("@/config");
export default {
  data() {
    return {
      s: ''
    }
  },
  created() {
    this.s = urls[process.env.NODE_ENV];
  }
};
</script>
<style scoped>
  #op-stage{
    opacity: 0;
    width: 0;
    height: 0;
  }
</style>