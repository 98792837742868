import dates from "@/utils/dates";
export const filters = [
  {
    name: "formatTime",
    fun: (value) => {
      if (!value) return "- -";
      value = value * 1 * 1000;
      return dates.formatDate(new Date(value), "yyyy-MM-dd hh:mm");
    },
  },
  {
    name: "formatDate",
    fun: (value) => {
      if (!value) return "- -";
      value = value * 1 * 1000;
      return dates.formatDate(new Date(value), "yyyy-MM-dd");
    },
  },
  {
    name: "formatStudyTimelong",
    fun: (value) => {
      value *= 1;
      let s = parseInt(value / 1000);
      let hours = parseInt(s / 3600);
      let minutes = parseInt((s % 3600) / 60);
      let seconds = (s % 3600) % 60;

      let str = "";
      if (hours > 0) {
        str += hours + "小时";
      }
      if (minutes > 0) {
        str += minutes + "分";
      }
      return str + seconds + "秒";
    },
  },
  {
    name: "transToMinutes",
    fun: (value) => {
      let minutes = value / 60000;
      let m = minutes.toFixed(0);
      return m + "分";
    },
  },

  {
    name: "size",
    fun: (val) => {
      let s = val / 1000;
      let u = "KB";
      if (s > 1000) {
        s = s / 1000;
        u = "M";
      }
      return s.toFixed(2) + u;
    },
  },
  // 没有数据，用--代替
  {
    name: "nullData",
    fun: (val) => {
      if (!val) return "——";
      return val;
    },
  },
  {
    name: "sex",
    fun: (val) => {
      // if(!val) return "——"
      val *= 1;
      switch (val) {
        case 1: {
          return "男";
        }
        case 2: {
          return "女";
        }
        default: {
          return "——";
        }
      }
    },
  },
  {
    name: "publishState",
    fun: (val) => {
      val = val * 1;
      if (val == 1) {
        return "已上架";
      } else {
        return "未上架";
      }
    },
  },
  {
    name: "feedbackType",
    fun: (val) => {
      val = val * 1;
      let res = "";
      switch (val) {
        case 0:
          res = "功能异常";
          break;
        case 1:
          res = "课程反馈";
          break;
        case 2:
          res = "优化建议";
          break;
        case 3:
          res = "其他反馈";
          break;
        default:
          res = "- -";
      }
      return res;
    },
  },
  {
    name: "platform",
    fun: (val) => {
      val = val * 1;
      let res = "";
      switch (val) {
        case 0:
          res = "IOS";
          break;
        case 1:
          res = "Android";
          break;
        default:
          res = "- -";
      }
      return res;
    },
  },
  {
    name: "transToMinutes",
    fun: (value) => {
      let minutes = value / 60000;
      let m = minutes.toFixed(0);
      return m + "分";
    },
  },
  {
    name: "formatSeconds",
    fun: (val) => {
      let days = Math.floor(val / 86400);
      let hours = Math.floor((val % 86400) / 3600);
      let minutes = Math.floor(((val % 86400) % 3600) / 60);
      let seconds = Math.floor(((val % 86400) % 3600) % 60);
      let forMatDate = {
        days: days > 0 ? days + "天" : "",
        hours: hours > 0 ? hours + "小时" : "",
        minutes: minutes > 0 ? minutes + "分钟" : "",
        seconds: seconds > 0 ? seconds + "秒" : "",
      };
      let str = "";
      for (let p in forMatDate) {
        str += forMatDate[p];
      }
      return str;
    },
  },
  // demo
  // {
  // 	name: 'name',
  // 	fun: (value) => {
  // 		return value;
  // 	}
  // },
];
