export default [
    {
        path:"/goods",
        name:"goods",
        component: () =>import("@/pages/goods/index"),
        meta:{
            breadcrumb:[
                {
                    path:"/goods",
                    text:"商品管理"
                },
                {
                    path:"",
                    text:"商品分類"
                }
            ]
        }
    },
    {
        path:"/goods/exposure",
        name:"goodsExposure",
        component: () =>import("@/pages/goods/exposure"),
        meta:{
            breadcrumb:[
                {
                    path:"/goods",
                    text:"商品管理"
                },
                {
                    path:"",
                    text:"曝光套餐"
                }
            ]
        }
    },
    {
        path:"/goods/list",
        name:"goodsList",
        component: () =>import("@/pages/goods/list"),
        meta:{
            breadcrumb:[
                {
                    path:"/goods",
                    text:"商品管理"
                },
                {
                    path:"",
                    text:"商品列表"
                }
            ]
        }
    },
    {
        path:"/goods/payment",
        name:"goodsPayment",
        component: () =>import("@/pages/goods/payment"),
        meta:{
            breadcrumb:[
                {
                    path:"/goods",
                    text:"商品管理"
                },
                {
                    path:"",
                    text:"支付記錄"
                }
            ]
        }
    },
    {
        path:"/goods/words",
        name:"goodsWords",
        component: () =>import("@/pages/goods/words"),
        meta:{
            breadcrumb:[
                {
                    path:"/goods",
                    text:"商品管理"
                },
                {
                    path:"",
                    text:"違禁詞管理"
                }
            ]
        }
    },
]