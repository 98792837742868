/*
 * @Description: 用户管理路由
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 21:40:05
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-09 10:37:43
 */
export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/pages/user/index'),
    meta: {
      breadcrumb: [
        {
          path: '/user',
          text: '用戶管理'
        },
        {
          path: '',
          text: '用戶列表'
        }
      ]
    }
  },
  {
    path: '/user/merchant',
    name: 'goodsMerchant',
    component: () => import('@/pages/user/merchant'),
    meta: {
      breadcrumb: [
        {
          path: '/user',
          text: '用戶管理'
        },
        {
          path: '',
          text: '註冊商戶'
        }
      ]
    }
  },
  {
    path: '/user/notice',
    name: 'userNotice',
    component: () => import('@/pages/user/notice'),
    meta: {
      breadcrumb: [
        {
          path: '/user',
          text: '用戶管理'
        },
        {
          path: '',
          text: '通知管理'
        }
      ]
    }
  }
]
