<template>
  <div class="c-thumb-wrap" :style="{width: size.width + 'px', height: size.height + 'px'}">
    <el-image
      v-if="src"
      :style="{width: size.width+'px', height: size.height + 'px'}"
      :src="src"
      :fit="fit"
      @load="load"
    ></el-image>
    <div v-else :style="{width: size.width+'px', height: size.height + 'px'}" class="img-default"></div>
    <div class="c-mask" v-if="!disabled">
      <el-link :underline="false" @click="selectMedia">
        <i class="el-icon-edit-outline f20"></i>
      </el-link>
      <el-link :underline="false" @click="deleteSelect">
        <i class="el-icon-delete f20 ml15"></i>
      </el-link>
    </div>
    <!-- <div class="loading" v-if="loading" v-loading="loading"></div> -->
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: "",
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: 100,
          height: 100,
        };
      },
    },
    fit: {
      type: String,
      default: 'fill',
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    selectMedia() {
      this.$emit("select");
    },
    deleteSelect() {
      this.$emit("delete");
    },
    load() {
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.c-thumb-wrap {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
}
.img-default {
  border: 1px dashed #efefef;
  background: #eee url(~@/assets/images/default.png) no-repeat 50% 50% / auto
    60% !important;
}
.c-thumb-wrap:hover .c-mask {
  display: flex;
}
.c-mask,
.loading {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}
.loading {
  display: block;
}
/* .el-link.el-link--default{color: #fff} */
/* .el-link.el-link--default:hover{color: #409EFF} */
/deep/.el-image img{
  background: #eee;
}
</style>
