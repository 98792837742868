/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2020-11-03 16:04:51
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-07 14:39:54
 */
import Vue from 'vue'
import Vuex from 'vuex'

// 各模块引入
/** system */
// import system from './modules/system/account';
/** */

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    // system,
  }
})
export default store
