/*
 * @Description: 广告管理接口
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-01-21 22:05:23
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-03 09:25:04
 */
import system from './modules/system'
import ads from './modules/ads'
import news from './modules/news'
import goods from './modules/goods'
import user from './modules/user'
import help from './modules/help'
import report from './modules/report'
import statistics from './modules/statistics'
export default {
  system,
  ads,
  news,
  goods,
  user,
  help,
  report,
  statistics,
}
