/*
 * @Description: 举报管理路由
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 21:40:05
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-09 10:38:26
 */
export default [
  {
    path: '/report/goods',
    name: 'reportGoods',
    component: () => import('@/pages/report/goods'),
    meta: {
      breadcrumb: [
        {
          path: '/report/goods',
          text: '舉報管理'
        },
        {
          path: '',
          text: '商品舉報記錄'
        }
      ]
    }
  },
  {
    path: '/report/user',
    name: 'reportUser',
    component: () => import('@/pages/report/index'),
    meta: {
      breadcrumb: [
        {
          path: '/report/goods',
          text: '舉報管理'
        },
        {
          path: '',
          text: '用戶舉報記錄'
        }
      ]
    }
  },
]
