/*
 * @Description: }
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-01-21 22:05:23
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-26 19:01:23
 */
/**
 * 存放一些页面内常量
 */
// oss地址
let imgUrl = ''
if (process.env.NODE_ENV == 'production') {
  imgUrl = 'http://oss.swapifly.com/'
} else {
  imgUrl = 'http://swapifly-oss.honglanshuzi.com/'
}
// 客户端地址
let clientUrl = ''
if (process.env.NODE_ENV == 'production') {
  clientUrl = 'https://swapifly.com'
} else {
  clientUrl = 'https://cms-api-v1.swapifly.com/swapifly'
}
export const constants = {
  imgUrl,
  /**商品狀態 */
  goodsState: {
    1: '出售中',
    2: '交易完成',
    3: '已下架',
  },
  /**商品封禁狀態 */
  goodsStatus: {
    1: '正常',
    2: '已封禁',
  },
  clientUrl: clientUrl,
}
