/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2021-12-22 21:27:27
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-07 14:32:32
 */
//组件统一出口
import thumb from "./thumb";
import uploadMaterial from "./uploadMaterial";
import uploadImg from "./uploadImg";

const views = {
  thumb, //图片组件
  //分类树侧边栏
  uploadMaterial, //上传
  uploadImg,
};

const install = function(Vue) {
  Object.keys(views).forEach((key) => {
    Vue.component(key, views[key]);
  });
};

const API = {
  install,
};

export default API;
