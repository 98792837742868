/*
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2020-07-07 14:33:34
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-02 19:10:44
 */
export default [
    {
        path:"/statistics",
        name:"statistics",
        component: () =>import("@/pages/statistics/user/index"),
        meta:{
            breadcrumb:[
                {
                    path:"/statistics",
                    text:"數據統計"
                },
                {
                    path:"",
                    text:"用戶數據概覽"
                }
            ]
        }
    },
    {
        path:'/statistics/user/detail',
        name:"statisticsUserDetail",
        component: () =>import("@/pages/statistics/user/user"),
        meta:{
            breadcrumb:[
                {
                    path:"/statistics",
                    text:"用戶數據概覽"
                },
                {
                    path:"",
                    text:"用戶統計"
                }
            ]
        }
    },
    {
        path:"/statistics/goods",
        name:"statisticsGoods",
        component: () =>import("@/pages/statistics/goods/index"),
        meta:{
            breadcrumb:[
                {
                    path:"/statistics",
                    text:"數據統計"
                },
                {
                    path:"",
                    text:"商品數據概覽"
                }
            ]
        }
    },
    {
        path:'/statistics/goods/detail',
        name:"statisticsGoodsDetail",
        component: () =>import("@/pages/statistics/goods/goods"),
        meta:{
            breadcrumb:[
                {
                    path:"/statistics/goods",
                    text:"商品數據概覽"
                },
                {
                    path:"",
                    text:"商品統計"
                }
            ]
        }
    },
]