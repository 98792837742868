/*
 * @Description:
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-01-21 22:05:23
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-21 17:57:07
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/utils/auth'

import login from './login'
import home from './home'
import system from './system'
import ads from './ads'
import news from './news'
import goods from './goods'
import report from './report'
import user from './user'
import help from './help'
import statistics from './statistics'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const config = [
  login,
  {
    path: '/',
    name: 'layout',
    component: () => import('@/pages/layout'),
    children: [
      home,
      ...system,
      ...ads,
      ...goods,
      ...user,
      ...report,
      ...news,
      ...help,
      ...statistics,
    ],
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/pages/404'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes: config,
})

router.beforeEach((to, from, next) => {
  let userInfo = auth.getAuth()
  let isLogin = userInfo.token ? true : false
  // console.log(isLogin, '!!!!!!!!!!!!!!')
  if (isLogin) {
    // 已经登录
    if (to.path == '/login') {
      router.push('/home')
    } else {
      next()
    }
  } else {
    // 未登录
    if (to.path == '/login') {
      next()
    } else {
      router.push('/login')
    }
  }
})
// router.beforeRouteLeave ((to, from, next)=>{
//     localStorage.getItem('noteShow')? localStorage.removeItem('noteShow') :'
//       localStorage.getItem('show') ? localStorage.removeItem('show'):'
//       next()
// })

export default router
